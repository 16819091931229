<template>
  <div class="main">
    <img class="centers-img" :src="now" />
    <div class="centers">
      <div>
        <van-tabs v-model="active" @click="onClick">
          <van-tab title="登录">
            <van-cell-group>
              <van-field
                v-model="user"
                label-align="center"
                type="text"
                label="账号"
                placeholder="请输入账号"
                @focus="focus_user"
                @blur="blur"
              />
              <van-field
                v-model="pass"
                label="密码"
                placeholder="请输入密码"
                type="password"
                label-align="center"
                @focus="focus_pass"
                @blur="blur"
              />
              <van-button type="info" block="block" @click="btn_login"
                >登录</van-button
              >
            </van-cell-group>
          </van-tab>

          <van-tab title="注册" v-if="!diableRegister">
            <van-field
              v-model="user"
              label-align="center"
              label="账号"
              placeholder="请输入账号"
              @focus="focus_user"
              @blur="blur"
            />
            <van-field
              v-model="pass"
              label="密码"
              label-align="center"
              placeholder="请输入密码"
              @focus="focus_pass"
              @blur="blur"
            />
            <van-field
              v-model="suppass"
              label="安全码"
              type="number"
              label-align="center"
              placeholder="用于修改密码(输入数字)"
              @focus="focus_pass"
              @blur="blur"
            />
            <van-field
              v-model="QQ"
              label="QQ"
              type="number"
              label-align="center"
              placeholder="用于售后"
              @focus="focus_user"
              @blur="blur"
            />
            <van-button type="info" block="block" @click="btn_register"
              >注册</van-button
            >
          </van-tab>

          <van-tab title="修改密码">
            <van-field
              v-model="user"
              label-align="center"
              label="账号"
              placeholder="请输入账号"
              @focus="focus_user"
              @blur="blur"
            />
            <van-field
              v-model="newpass"
              label="密码"
              label-align="center"
              placeholder="请输入新的密码"
              @focus="focus_pass"
              @blur="blur"
            />
            <van-field
              v-model="newsuppass"
              label="安全码"
              type="number"
              label-align="center"
              placeholder="注册时的安全码"
              @focus="focus_pass"
              @blur="blur"
            />

            <van-button type="info" block="block" @click="btn_change"
              >修改密码</van-button
            >
          </van-tab>
        </van-tabs>
      </div>
      <div class="msg" v-if="this.$api.IsAgent==false">
        <van-checkbox v-model="checked" >
          按照用户协议使用且同意<a href="/xieyi.html">用户协议</a></van-checkbox
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  name: "HelloWorld",
  methods: {
    blur() {
      this.now = require("../assets/img/normal.png");
    },
    focus_user() {
      this.now = require("../assets/img/greeting.png");
    },
    focus_pass() {
      this.now = require("../assets/img/blindfold.png");
    },
    onClick(name, title) {
      if (title === "注册") {
        Dialog.alert({
          message: " 账号密码不要和其他平台一样,已经有人因此造成损失",
        }).then(() => {
          // on close
        });
      }
    },
    async btn_login() {
      if (this.checked === false) {
        Dialog.alert({
          message: "请同意用户协议",
        });
        return;
      }
      if (this.user.length === 0 || this.pass.length === 0) {
        Dialog.alert({
          message: "账号密码没输入",
        });
        return;
      }
      let res = await this.$api.fn_logins(this.user, this.pass);
      res = res.data;
      const token = res.token;
      if (!token) {
        Dialog.alert({
          message: res.message,
        });
      } else {
        localStorage.setItem("token", token);
        localStorage.setItem("name", this.user);
        localStorage.setItem("id", res.data[0].id);
        this.$emit("isshow", true);

        let gonggao = res.data[0].用户公告;
        while (gonggao.indexOf("\\n\\n") !== -1) {
          gonggao = gonggao.replace("\\n\\n", "\n");
        }

        if (this.$api.IsAgent==false) {
          Dialog.alert({
            message: gonggao,
          }).then(() => {
            // on close
          });
        }
      }
    },
    async btn_register() {
      if (this.$api.IsAgent == true) {
        if (
          localStorage.getItem("agent_uid") == "" ||
          localStorage.getItem("agent_uid") == 0
        ) {
          Dialog.alert({
            message: "此网站无权注册",
          });
          return;
        }
      }

      if (this.checked === false) {
        Dialog.alert({
          message: "请同意用户协议",
        });
        return;
      }
      let referee = localStorage.getItem("Referee");
      if (typeof referee !== "string") {
        referee = "";
      }
      if (
        this.user.length === 0 ||
        this.pass.length === 0 ||
        this.QQ.length === 0 ||
        this.suppass.length === 0
      ) {
        Dialog.alert({
          message: "注册请输入完整的信息",
        });
        return;
      }
      //  账号，密码，安全答案，QQ,推荐人
      let res = "";
      if (this.$api.IsAgent == true) {
        res = await this.$api.fn_register(
          this.user,
          this.pass,
          this.suppass,
          this.QQ,
          "",
          localStorage.getItem("agent_uid")
        );
      } else {
        res = await this.$api.fn_register(
          this.user,
          this.pass,
          this.suppass,
          this.QQ,
          referee
        );
      }
      res = res.data;
      if (res.message === "注册成功") {
        this.$dialog.alert({ message: "注册成功,即将跳转到下载页" });
        let ret = await this.$api.fn_getdownload();
        ret = ret.data;
        window.open(ret);
      } else {
        this.$dialog.alert({ message: res.message });
      }
    },
    //  修改密码
    async btn_change() {
      if (
        this.user.length === 0 ||
        this.newpass.length === 0 ||
        this.newsuppass.length === 0
      ) {
        Dialog.alert({
          message: "请输入完整的信息",
        });
        return;
      }
      //  账号，密码，安全答案，QQ,推荐人
      let res = await this.$api.fn_changePassword(
        this.user,
        this.newpass,
        this.newsuppass
      );
      res = res.data;
      if (res.message === "ok") {
        this.$dialog.alert({ message: "修改成功" });
      } else {
        this.$dialog.alert({ message: res.message });
      }
    },
  },
  data() {
    return {
      active: 0,
      now: require("../assets/img/normal.png"),
      user: "",
      pass: "",
      suppass: "",
      QQ: "",
      block: true,
      checked: true,
      newpass: "",
      newsuppass: "",
      path: "",
      diableRegister: false,
    };
  },
  created() {
    if (this.$api.IsAgent == true) {
      if (!localStorage.getItem("agent_uid")) {
        this.diableRegister = true;
        return;
      }
    }

    const href = window.location.href;

    if (href.includes("invite") && href.includes("login")) {
      let startDelimiter = "invite/";
      let endDelimiter = "#/";
      let startIndex = href.indexOf(startDelimiter) + startDelimiter.length;
      let endIndex = href.indexOf(endDelimiter);
      let Referee = href.substring(startIndex, endIndex);
      localStorage.setItem("Referee", Referee);
    }
  },
};
</script>

<style scoped>
.main {
  overflow: hidden;
  background: url("../assets/img/3.jpg") no-repeat;
  height: 100%;
  z-index: -10;
  zoom: 1;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.centers-img {
  height: 3rem;
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 2.5rem;
  z-index: 99;
}

.centers {
  margin-top: 4rem;
  text-align: center;
  background: hsl(20, 12%, 90%);
  opacity: 0.8;
  filter: Alpha(opacity=80);
  /* IE8 以及更早的浏览器 */
  border-radius: 0.35rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
}

.msg {
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.5rem;
  text-align: left;
  color: #545454;
}
</style>